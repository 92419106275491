import React, { FC } from "react";
import { Badge, Card } from "react-bootstrap";
import { EducationProps } from "config/data";
import { isNull } from "lodash";

interface EducationItemProps {
  data: EducationProps;
}

const EducationItem: FC<EducationItemProps> = ({ data }) => {
  return (
    <Card className="border-0">
      <Card.Header className="border-bottom-0 d-flex justify-content-between align-items-start">
        <div className="me-auto">
          <Card.Title>{data.school}</Card.Title>
          <Card.Subtitle>{`${data.degree} ${
            !isNull(data.domain) ? " in " + data.domain : ""
          }`}</Card.Subtitle>
        </div>
        <Badge bg="primary" pill>
          {`${data.from} - ${data.to}`}
        </Badge>
      </Card.Header>
    </Card>
  );
};

export default EducationItem;
