import React from "react";
import { Accordion, Card, Container, ListGroup, Stack } from "react-bootstrap";
import AccordionItem from "components/AccordionItem";
import ExperienceItem from "app/resume/components/ExperienceItem";
import EducationItem from "app/resume/components/EducationItem";
import jsonData from "config/data.json";

const ResumePage = () => {
  return (
    <Container>
      <Stack direction="vertical" gap={2}>
        <Card border="primary" bg="primary" text="white">
          <Card.Body>
            Accomplished and skilled Technical Leader with over 10 years of
            experience. Comprehensive knowledge of all aspects of custom
            software design including business analysis, database design and
            software development. Eager to apply comprehensive software creation
            and maintenance expertise for a renowned organization.
          </Card.Body>
        </Card>
        <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen flush={true}>
          <AccordionItem eventKey="0" title="PROFESSIONAL EXPERIENCE">
            <Stack direction="vertical" gap={4}>
              {jsonData.experiences.map((item) => (
                <ExperienceItem data={item} />
              ))}
            </Stack>
          </AccordionItem>
          <AccordionItem eventKey="1" title="EDUCATION">
            <Stack direction="vertical" gap={2}>
              {jsonData.education.map((item) => (
                <EducationItem data={item} />
              ))}
            </Stack>
          </AccordionItem>
          <AccordionItem eventKey="2" title="PROFESSIONAL SKILLS">
            <ListGroup as="ol" className="rounded-0 list-group-flush">
              {jsonData.skills.map((skill) => (
                <ListGroup.Item>{skill.label}</ListGroup.Item>
              ))}
            </ListGroup>
          </AccordionItem>
        </Accordion>
      </Stack>
    </Container>
  );
};

export default ResumePage;
