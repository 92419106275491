import React, { FC } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { CertificationProps } from "config/data";
import JavaSvg from "assets/icons8-java.svg";
import JavaSvg1 from "assets/icons8-java.svg";

interface CertificationItemProps {
  data: CertificationProps;
}

const CertificationItem: FC<CertificationItemProps> = ({ data }) => {
  return (
    <Card className="p-4">
      <Row className="align-items-center">
        <Col className="col-md-2">
          <Image roundedCircle fluid src={JavaSvg} alt="me" width={100} />
        </Col>
        <Col className="col-md">
          <Card className="border-0">
            <Card.Title className="mb-3">{data.title}</Card.Title>
            <Card.Subtitle> {`Date earned ${data.earnedAt}`}</Card.Subtitle>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default CertificationItem;
