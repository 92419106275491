import React from "react";
import { Button, Container, Stack } from "react-bootstrap";
import { Download } from "react-bootstrap-icons";

const DownloadButton = () => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.download = "Boubacar Bah CV";

    // 👇️ set to relative path
    link.href = "/pdf/BoubacarBah.pdf";

    link.click();
  };

  return (
    <Button
      variant="secondary"
      size="sm"
      //href={CV}
      onClick={handleDownload}
    >
      <Stack direction="horizontal" gap={0.5}>
        <Download />
        <Container>Download CV</Container>
      </Stack>
    </Button>
  );
};

export default DownloadButton;
