import React, { FC } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  ListGroup,
  Row,
  Stack,
} from "react-bootstrap";
import ProfilePic1 from "assets/images/profile1.jpg";
import JavaSvg1 from "assets/icons8-java.svg";
import ImageCard from "app/about/image";
import JavaSvg from "assets/icons8-java.svg";
import {
  Twitter,
  Facebook,
  Instagram,
  Github,
  Mailbox,
  Linkedin,
} from "react-bootstrap-icons";

interface ButtonWithIconProps {
  label: any;
  icon: any;
  href: string;
}

const ButtonWithIcon: FC<ButtonWithIconProps> = ({ label, icon, href }) => {
  return (
    <Button variant="primary" href={href}>
      <Stack direction="horizontal">
        {icon}
        <Container>{label}</Container>
      </Stack>
    </Button>
  );
};

const AboutPage = () => {
  return (
    <Container>
      <Stack gap={4}>
        <Card className="border-0">
          <Row className="align-items-start">
            <Col className="col-md-4">
              <Image rounded fluid src={ProfilePic1} alt="me" />
            </Col>
            <Col className="col-md">
              <Card className="border-0">
                <Card.Body className="p-0 pb-4">
                  <Stack gap={4}>
                    <Container>
                      I was born in 1987 in Guinea. I live in Casablanca Morocco
                      and work as a software engineer. I graduated with a degree
                      in GIS engineering. I chose that field because I’ve always
                      been interested in Maths, Geometry, Maps and algorithms.
                    </Container>
                    <Container>
                      I have been a software developer for more than 10 years,
                      with good knowledge of frontend and backend techniques. I
                      have mostly worked professionally with Java, PHP, AS3, and
                      ReactJS, but also a title bit with Python and React
                      native.
                    </Container>
                    <Container>
                      Beside programming I am interested in sports particularly
                      running and basketball. I am also very interested in
                      hiking that allows me to be in contact with nature,
                      strengthen my relationship by meeting new friends. Walking
                      through nature and breathing clean air help me to relax
                      and get away from the stress of the city.
                    </Container>
                    <Container>
                      I like reading because it gives me an opportunity to use
                      my imagination and it’s sometimes just nice to experience
                      someone else's thoughts. I especially like reading
                      business and personal development books.
                    </Container>
                  </Stack>
                </Card.Body>
                <Card.Footer className="bg-transparent">
                  <Stack
                    className="justify-content-end"
                    direction="horizontal"
                    gap={1}
                  >
                    <ButtonWithIcon
                      icon={<Github />}
                      label="GitHub"
                      href="https://github.com/boubcr"
                    />
                    <ButtonWithIcon
                      icon={<Mailbox />}
                      label="Mail"
                      href="mailto:boubcr.bah@gmail.com"
                    />
                    <ButtonWithIcon
                      icon={<Linkedin />}
                      label="LinkedIn"
                      href="https://www.linkedin.com/in/boubcr-bah"
                    />
                    <ButtonWithIcon
                      icon={<Twitter />}
                      label="Twitter"
                      href="https://"
                    />
                    <ButtonWithIcon
                      icon={<Facebook />}
                      label="Facebook"
                      href="https://"
                    />
                  </Stack>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Card>
        {/*<Container>*/}
        {/*  <Row className="row-cols-3">*/}
        {/*    <ImageCard />*/}
        {/*    <ImageCard />*/}
        {/*    <ImageCard />*/}
        {/*    <ImageCard />*/}
        {/*    <ImageCard />*/}
        {/*    <ImageCard />*/}
        {/*    <ImageCard />*/}
        {/*  </Row>*/}
        {/*</Container>*/}
      </Stack>
    </Container>
  );
};

export default AboutPage;
