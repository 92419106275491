import React from "react";
import { Stack } from "react-bootstrap";
import { CertificationProps } from "config/data";
import CertificationItem from "app/certifications/item";

const data: CertificationProps[] = [
  { id: 1, title: "OCA", earnedAt: "01/06/2021" },
  { id: 2, title: "OCP", earnedAt: "12/11/2021" },
];

const Certifications = () => {
  return (
    <Stack gap={3}>
      {data.map((item) => (
        <CertificationItem data={item} />
      ))}
    </Stack>
  );
};

export default Certifications;
