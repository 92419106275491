import React, { cloneElement, FC, ReactElement } from "react";
import { Accordion } from "react-bootstrap";

interface AccordionItemProps {
  title: any;
  eventKey: string;
  children: ReactElement;
  params?: any;
}

const AccordionItem: FC<AccordionItemProps> = ({
  title,
  eventKey,
  params,
  children,
}) => {
  return (
    <Accordion.Item eventKey={eventKey} className="border-0">
      <Accordion.Header className="rounded-4">{title}</Accordion.Header>
      <Accordion.Body className="ps-0 pe-0">
        {cloneElement(children, params)}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default AccordionItem;
