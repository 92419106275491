import React, { FC } from "react";
import { Badge, Card, ListGroup, Stack } from "react-bootstrap";
import { ExperienceProps } from "config/data";
import { isNull } from "lodash";

interface ExperienceItemProps {
  data: ExperienceProps;
}

const ExperienceItem: FC<ExperienceItemProps> = ({ data }) => {
  return (
    <Card className="border-0">
      <Card.Header className="border-bottom-0 d-flex justify-content-between align-items-center">
        <div className="me-auto">
          <Card.Title>{data.title}</Card.Title>
          <Card.Subtitle>
            {data.company}, {data.city}
          </Card.Subtitle>
        </div>
        <Badge bg="primary" pill>
          {data.from} - {!isNull(data.to) ? data.to : "Today"}
        </Badge>
      </Card.Header>
      <Card.Body className="ps-0 pe-0">
        <ListGroup
          as="ol"
          className="rounded-0 list-group-flush list-group-numbered"
        >
          {data.tasks.map((task) => (
            <ListGroup.Item>{task.label}</ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
      <Card.Footer className="border-0  bg-transparent">
        <Stack direction="horizontal" gap={2} className="d-flex flex-wrap">
          {data.tools.map((tool) => (
            <Badge bg="info">{tool.name}</Badge>
          ))}
        </Stack>
      </Card.Footer>
    </Card>
  );
};

export default ExperienceItem;
