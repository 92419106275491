import React from "react";
import { Container } from "react-bootstrap";
import Navigation from "components/Navigation";
import { Route, Routes } from "react-router-dom";
import NotFoundPage from "app/notFound";
import AboutPage from "app/about";
import ResumePage from "app/resume";
import Certifications from "app/certifications";

const App = () => {
  return (
    <>
      <Navigation />
      <Container className="container mt-4 mb-4">
        <Routes>
          {/*<Route path="/" element={<HomePage />} />*/}
          {/*<Route path="/resume" element={<ResumePage />} />*/}
          <Route path="/" element={<ResumePage />} />
          <Route path="/certifications" element={<Certifications />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Container>
    </>
  );
};

export default App;
