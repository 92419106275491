import { Navbar, Container, Nav, Button, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import DownloadButton from "components/DownloadButton";
// import { useShoppingCart } from "../context/ShoppingCartContext";

const Navigation = () => {
  // const { openCart, cartQuantity } = useShoppingCart();
  const dd = (
    <Navbar sticky="top" className="bg-white shadow-sm mb-3">
      {/*<Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>*/}
      <Container>
        <Nav className="me-auto">
          <Nav.Link to={"/"} as={NavLink}>
            Home
          </Nav.Link>
          <Nav.Link to={"/resume"} as={NavLink}>
            Resume
          </Nav.Link>
          <Nav.Link to={"/certifications"} as={NavLink}>
            Certifications
          </Nav.Link>
          <Nav.Link to={"/about"} as={NavLink}>
            About
          </Nav.Link>
        </Nav>
        <Button variant="secondary" size="sm">
          Download CV
        </Button>
        {/*{cartQuantity > 0 && (*/}
        {/*  <Button*/}
        {/*    onClick={openCart}*/}
        {/*    style={{ width: "3rem", height: "3rem", position: "relative" }}*/}
        {/*    variant="outline-primary"*/}
        {/*    className="rounded-circle"*/}
        {/*  >*/}
        {/*    <svg fill="currentColor" viewBox="0 0 16 16">*/}
        {/*      {" "}*/}
        {/*      <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />{" "}*/}
        {/*    </svg>*/}
        {/*    <div*/}
        {/*      className="rounded-circle bg-danger d-flex justify-content-center align-items-center"*/}
        {/*      style={{*/}
        {/*        color: "white",*/}
        {/*        width: "1.5rem",*/}
        {/*        height: "1.5rem",*/}
        {/*        position: "absolute",*/}
        {/*        bottom: 0,*/}
        {/*        right: 0,*/}
        {/*        transform: "translate(25%,25%)",*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {cartQuantity}*/}
        {/*    </div>*/}
        {/*  </Button>*/}
        {/*)}*/}
      </Container>
    </Navbar>
  );

  return (
    <Navbar expand="lg" sticky="top" className="bg-white shadow-sm mb-3">
      <Container>
        <Navbar.Brand to={"/"} as={NavLink}>
          Boubacar Bah
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/*<Nav.Link to={"/"} as={NavLink}>*/}
            {/*  Home*/}
            {/*</Nav.Link>*/}
            {/*<Nav.Link to={"/resume"} as={NavLink}>*/}
            {/*  Resume*/}
            {/*</Nav.Link>*/}
            <Nav.Link to={"/"} as={NavLink}>
              Resume
            </Nav.Link>
            <Nav.Link to={"/certifications"} as={NavLink}>
              Certifications
            </Nav.Link>
            <Nav.Link to={"/about"} as={NavLink}>
              About
            </Nav.Link>
            {/*<NavDropdown title="Dropdown" id="basic-nav-dropdown">*/}
            {/*  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>*/}
            {/*  <NavDropdown.Item href="#action/3.2">*/}
            {/*    Another action*/}
            {/*  </NavDropdown.Item>*/}
            {/*  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
            {/*  <NavDropdown.Divider />*/}
            {/*  <NavDropdown.Item href="#action/3.4">*/}
            {/*    Separated link*/}
            {/*  </NavDropdown.Item>*/}
            {/*</NavDropdown>*/}
          </Nav>
          <DownloadButton />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
